<template>
  <div>
    <div
      class="mats-listing__wrapper color-listing__wrapper"
      style="margin-top: 0px; margin-left: 15px"
    >
      <div style="width: 100%; padding-top: 0px; margin-top: 15px">
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t('ArticleName') }}
              </th>
              <th
                v-if="getLoggedInUser.role !== 'Designer' && getLoggedInUser.role !== 'SalesPerson'"
              >
                {{ $t('Assign') }}
              </th>
              <th>
                {{ $t("Actions") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in getCreatedOrdersByOrderNumber"
              :key="index"
            >
              <td>{{ item.articleNumber }}</td>
              <td>
                <button
                  v-if=" item.orderItemState == 0"
                  id="assign"
                  v-b-modal.add-team-modal
                  class="editButton"
                  style="width: 120px !important;"
                  @click="getOrderItem(item)"
                >
                  {{ $t('AssignTeam') }}
                </button>
              </td>
              <td>
                <button
                  v-b-toggle.material-toggle
                  class="editButton"
                  @click="openToggleMaterial(item.orderItemId)"
                >
                  {{ $t('Edit') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
    <AddTeamModal @assign="addTeamToArticle" />
    <EditMaterialToggle
      @editMaterialsOrder="editMaterialOrder"
      @onCancelToggle="onCancelToggle"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddTeamModal from '@/components/orders/modals/AddTeamModal.vue';
import EditMaterialToggle from './EditMaterialToggle.vue'


export default {
  components: {
    AddTeamModal,
    EditMaterialToggle,
  },
  data() {
    return {
      selectedOrderItem: null,
      orderId: null,
      isSidebarOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getCreatedOrdersByOrderNumber',
    ]),
  },
  methods: {
    ...mapActions(['assignOrderItemToTeamLeader',
      'resetCreatedOrdersByOrderNumberMaterials',
      'get_edit_OrderItem', 'edit_OrderItem_MaterialVariants', 'teamNames', 'createdMaterialOrders', 'loadCreatedOrdersByOrderNumber', 'getMaterialVariantsToMDCPagination']),
    editMaterialOrder(value) {
      console.log('objekti', value)
      this.isSidebarOpen = false;
    },
    openToggleMaterial(value) {
      this.isSidebarOpen = true;
      // this.get_edit_OrderItem(value)
      this.edit_OrderItem_MaterialVariants(value)
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    getOrderItem(orderItem) {
      this.teamNames({
        pageNumber: 1,

        pageSize: 15,

      });
      this.selectedOrderItem = orderItem.orderItemId
      this.orderId = orderItem.orderNumber
    },
    async addTeamToArticle(obj) {
      const objToSend = {
        orderNumber: this.orderId,
        orderItemId: this.selectedOrderItem,
        teamNameId: obj.value,
      };
      await this.assignOrderItemToTeamLeader({
        object: objToSend,
        successCallback: () => {
          this.resetCreatedOrdersByOrderNumberMaterials()
          this.createdMaterialOrders({
            pageNumber: 1,
            pageSize: 15,
            orderNumber: null,
            clientId: null,
          })
        },
      });
    },
  },
}
</script>

<style scoped>

</style>
