<template>
  <div>
    <div
      class="filtering"
    >
      <div class="search-toggle">

        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSarch === 'OrderNumber' }"
            @click="showOrderNumber(), toggleSarch = 'OrderNumber'"
          >
            {{ $t('OrderNumber') }}
          </p>
          <p
            :class="{ active2: toggleSarch === 'CustomerName' }"
            @click="showCustomerName(), toggleSarch = 'CustomerName'"
          >
            {{ $t('CustomerName') }}
          </p>
          <p
            :class="{ active2: toggleSarch === 'ShippingNotes' }"
            @click="showShippingNotes(), toggleSarch = 'ShippingNotes'"
          >
            {{ $t('ShippingNotes') }}
          </p>
        </div>
      </div>
      <div v-if="toggleSarch === 'OrderNumber'">
        <div class="black">
          <b-icon-search
            v-if="searchON == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchON != '' && !noSearch"
            class="search1"
            style="position: absolute; left: 200px; top: 14px; cursor: pointer"
            @click="searchON = ''"
          />
          <input
            v-model="searchON"
            type="text"
            name="search-box"
            :placeholder="$t('OrderNumber')"
            :style="getFilteredOrdersbyON.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"

            @keyup="inputChanged"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
        </div>
        <div
          v-if="getFilteredOrdersbyON.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;"
          :style="getFilteredOrdersbyON.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredOrdersbyON"
            :key="index"
            ref="options"
            :class="{ 'is-active': index === arrowCounter }"
            style="cursor: pointer"
            class="search-item"
            @click="searchByOn(result); noSearch = false"
          >
            <p style="margin: 0">
              {{ result }}
            </p>
          </div>
          <div
            v-if="getFilteredOrdersbyON.length == 0"
            class="search-item"
          >
            <p>{{ $t('NoResult') }}</p>
          </div>
        </div>
      </div>
      <div v-if="toggleSarch === 'CustomerName'">
        <div class="black">
          <b-icon-search
            v-if="searchCN == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchCN != '' && !noSearchTwo"
            class="search1"
            style="position: absolute; left: 200px; top: 14px; cursor: pointer"
            @click="searchCN = ''"
          />
          <div>
            <input
              v-model="searchCN"
              type="text"
              autocomplete="off"
              name="search-box"
              :placeholder="$t('CustomerName')"
              :style="getFilteredOrdersbyCN.length > 0 && noSearchTwo ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged2"
              @keydown.down="onArrowDown2"
              @keydown.up="onArrowUp2"
            >
          </div>
          <div
            v-if="getFilteredOrdersbyCN.length > 0 && noSearchTwo"
            ref="scrollContainer2"
            class="dropdrop"
            style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;
            "
            :style="getFilteredOrdersbyCN.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilteredOrdersbyCN"
              ref="options2"
              :key="index"
              :class="{ 'is-active': index === arrowCounter3 }"
              style="cursor: pointer"
              class="search-item"
              @click="searchByCn(result ); noSearchTwo = false"
            >
              <p style="margin: 0">
                {{ result.nameToDisplay }}
              </p>
            </div>
            <div
              v-if="getFilteredOrdersbyCN.length == 0"
              class="search-item"
            >
              <p>{{ $t('NoResult') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="toggleSarch === 'ShippingNotes'">
        <div class="black">
          <b-icon-search
            v-if="searchShip == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 13px"
          />
          <b-icon-x-circle
            v-else-if="searchShip != '' && !noSearchThree"
            class="search1"
            style="position: absolute; left: 200px; top: 14px; cursor: pointer"
            @click="searchShip = ''"
          />
          <input
            v-model="searchShip"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('ShippingNotes')"
            :style="getFilteredOrdersByShipment.length > 0 && noSearchThree ? 'border-radius: 8px 8px 0px 0px' : ''"
            @keyup="inputChanged3"
            @keydown.down="onArrowDown3"
            @keydown.up="onArrowUp3"
          >
        </div>
        <div
          v-if="getFilteredOrdersByShipment.length > 0 && noSearchThree"
          ref="scrollContainer2"
          class="dropdrop"
          style="
              display: inline-block;
              overflow: auto;
              position: absolute;
              background: white;
              z-index: 423 !important;
              width: 229px;
              box-shadow: none;
            "
          :style="getFilteredOrdersByShipment.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredOrdersByShipment"
            ref="options2"
            :key="index"
            :class="{ 'is-active': index === arrowCounter3 }"
            style="cursor: pointer"
            class="search-item"
            @click="searchByShip(result ); noSearchThree = false"
          >
            <p style="margin: 0">
              {{ result.notes }}
            </p>
          </div>
          <div
            v-if="getFilteredOrdersByShipment.length == 0"
            class="search-item"
          >
            <p>{{ $t('NoResult') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%; padding-top: 16px; margin-top: 0px;">
      <table
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t("No") }}
            </th>
            <th>
              {{ $t('OrderID') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('CustomerName') }}
            </th>
            <th>
              {{ $t('BillingCountry') }}
            </th>
            <th>
              {{ $t('ShippingCountry') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
            <!-- <th>
              {{ $t('AdjustedDelivery') }}
            </th> -->
            <!-- <th>
              {{ $t("Actions") }}
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) in getNewNominativeOrders"
            :key="order.id"
            class="hover-row"
            style="cursor: pointer"
            :style="classNameCh === order.orderNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            @click="ordersMaterials(order)"
          >
            <td>
              {{ getNewNominativeOrdersTotalItems - (pageNumber - 1) * pageSize - index }}.
            </td>
            <td>{{ order.orderNumber }}</td>
            <td>{{ $t(order.orderType) }}</td>
            <td>{{ order.customerName }}</td>
            <td>{{ order.billingAddressCountry }}</td>
            <td>{{ order.shippingAddressCountry }}</td>
            <td>{{ convertUTCDateToLoacalDate(order.created) }}</td>
            <!-- <td>{{ order.adjustedPickupDate }}</td> -->
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getNewNominativeOrdersTotalItems > 15"
          v-model="pageNumber"
          :total-rows="getNewNominativeOrdersTotalItems"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          size="md"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {},
  data() {
    return {
      pageNumber: 1,
      pageSize: 15,
      clientId: null,
      orderNumber: null,
      classNameCh: '',
      toggleSarch: 'OrderNumber',
      searchShip: '',
      searchCN: '',
      onSearchTwo: false,
      searchON: '',
      arrowCounter: 0,
      arrowCounter2: 0,
      arrowCounter3: 0,
      noSearch: false,
      noSearchTwo: false,
      noSearchThree: false,
      show: true,
    }
  },
  computed: {
    ...mapGetters(['getNewNominativeOrders',
      'getNewNominativeOrdersTotalItems',
      'getLanguage',
      'getFilteredOrdersbyON',
      'getFilteredOrdersbyCN',
      'getFilteredOrdersByShipment',
    ]),
  },
  watch: {
    pageNumber(value) {
      this.createdMaterialOrders({
        pageNumber: value,
        pageSize: this.pageSize,
        orderNumber: this.orderNumber,
        clientId: this.clientId,
      })
    },
    searchON(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.fshij();
          this.createdMaterialOrders({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            clientId: this.clientId,
            orderNumber: this.orderNumber,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByOrderNumber({ orderNumber: value, salesType: 'Material' });
          this.noSearch = true
        }
      }, 500);
    },
    searchCN(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.fshijCN();
          this.createdMaterialOrders({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            clientId: this.clientId,
            orderNumber: this.orderNumber,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByCustomerName(value);
          this.noSearchTwo = true
        }
      }, 500);
    },
    searchShip(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.createdMaterialOrders({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            clientId: this.clientId,
            orderNumber: this.orderNumber,
          })
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return
        } else {
          this.searchByShipmentCode(value);
          this.noSearchThree = true
        }
      }, 500);
    },
  },
  mounted() {
    this.createdMaterialOrders({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      orderNumber: this.orderNumber,
      clientId: this.clientId,
    })
  },
  methods: {
    ...mapActions(['createdMaterialOrders',
      'loadCreatedOrdersByOrderNumber',
      'searchByOrderNumber',
      'fshij',
      'searchByCustomerName',
      'fshijCN',
      'searchByShipmentCode',
    ]),
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    ordersMaterials(value) {
      this.classNameCh = value.orderNumber
      this.loadCreatedOrdersByOrderNumber(
        {
          id: value.orderNumber,
          pageNumber: 1,
          pageSize: 15,
        },
      )
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredOrdersbyON[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyON.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    onArrowDown2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 < this.getFilteredOrdersbyCN.length - 1) {
        this.arrowCounter2 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp2(ev) {
      ev.preventDefault()
      if (this.arrowCounter2 > 0) {
        this.arrowCounter2 -= 1;
        this.fixScrolling()
      }
    },

    onArrowDown3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 < this.getFilteredOrdersByShipment.length - 1) {
        this.arrowCounter3 += 1;
        this.fixScrolling();
      }
    },

    onArrowUp3(ev) {
      ev.preventDefault()
      if (this.arrowCounter3 > 0) {
        this.arrowCounter3 -= 1;
        this.fixScrolling()
      }
    },


    inputChanged2(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCn(this.getFilteredOrdersbyCN[this.arrowCounter2])
        this.removeLists()
        this.arrowCounter2 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    inputChanged3(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByShip(this.getFilteredOrdersByShipment[this.arrowCounter3])
        this.removeLists()
        this.arrowCounter3 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    showOrderNumber() {
      this.toggleSarch = 'OrderNumber';
      this.show = false
      this.searchCN = null;
      this.clientID = null;
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: this.clientID,
      })
    },
    showCustomerName() {
      this.toggleSarch = 'CustomerName';
      this.show = true
      this.searchON = null;
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: this.clientID,
      })
    },
    showShippingNotes() {
      this.toggleSarch = 'ShippingNotes';
      this.show = true
      this.searchON = null;
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: this.clientID,
      })
    },
    searchByOn(value) {
      this.searchON = value
      this.noSearch = false
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: this.clientID,
      })
      this.noSearch = false
    },
    searchByCn(value) {
      this.searchCN = value.nameToDisplay
      this.clientID = value.clientId
      this.noSearchTwo = false
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: value.clientId,
      })
    },
    searchByShip(value) {
      this.searchShip = value.notes
      this.notes = value.notes
      this.noSearchThree = false
      this.createdMaterialOrders({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        orderId: value.orderId,
        orderNumber: this.searchON == '' ? null : this.searchON,
        clientId: this.clientId,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.team_table td {
  padding: 12px 9px !important;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
  box-shadow: none;
  border: 1px solid  #e2e2e2;
};

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.active2{
  color: $base-color;
  font-weight: bold;
}
@media screen and (max-width: 1440px){
  .main {
    padding: 0 15px 15px 100px !important;
  }

  .table__search-input{
    padding: 100px 15px 15px 60px;
  }
  .search1{
  display: none;
}
.iconat{
  width:35px;
  height: 35px;
}
  .first-div{
    display: none;

  }
  .second-div{
    display: block;
  }
    .stage-card{
    width: 300px;
  }
}

@media screen and (max-width: 1300px) {
  .input2 {
    width: 160px !important;
    margin-left: 0px;
    font-size: smaller;
    margin-left: 4px !important;
    padding-left: 0px;
    text-align: end;
  }
  .input1 {
    width: 150px !important;
    margin-left: 0px;
    font-size: smaller;
  }
  .search1 {
    padding: 0px;
  }
  .custom-select1 {
    width: 80px !important;
    font-size: small;
  }
  .custom-select2 {
    width: 100px !important;
    font-size: small;
    padding-left: 0px;
    margin-left: 4px !important;
  }
  p {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1340px){
  .table__search-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 80px 0.75rem 15px 90px!important;
  }

  .search1{
    left: 328px !important;
    top: 83% !important;
  }
  .main {
    padding: 0 10px 15px 95px !important;
    /* justify-content: center; */
  }

  .header span {
    font-size: 13px;
    padding-top: 10px
  }

  .listContent p {
    font-size: 13px;
  }

  .header {
    width: 91%
  }
}
@media screen and (max-width: 1190px) {
  .table__search-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
    padding: 90px 0.75rem 15px 0.75rem !important;
  }

  .search1{
    left: 328px;
    top: 83%;
  }

  .header{
    width: 98%;
    padding: 30px 2px 10px 10px;
  }

  .header span {
    font-size: 13px;
  }

  .listContent p {
    font-size: 13px;
  }
  .main {
    padding: 0px 0.75rem 0 0.75rem !important;
    /* justify-content: center; */
  }
  .order-card {
    // flex-wrap: wrap;
    p {
      // flex-basis: 30%;
    }
  }
 }
@media only screen and (max-width: 800px) {
.material-con {
  // flex-direction: column;
  .material-info {
    // width: 100%;
    flex-basis: 40%;
  }
}
.table__search-input{
  margin-top: 13px;
  margin-left: 40px;
  padding: 110px 15px 15px 115px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.orderSearchBy{
  margin-bottom: 10px;
}

.search1{
  display: none;
}
}

@media screen and (max-width: 1100px){
  .search1{
    display: none;
  }
}

@media screen and (max-width: 1200px){
  .table__search-input{
    padding: 111px 15px 15px 65px;
  }

  .btn {
    margin-top: 20px !important;
  }

  .btnImg {
    margin-top: 20px !important;
  }

  .main{
  padding: 0 15px 15px 100px;
  }

  .search1{
    display: none;
  }
}
</style>
